var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Button ")])]}}])},[_c('span',[_vm._v("Tooltip")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"info"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":require("@/static/images/avatars/4.png")}})],1)]}}])},[_c('span',[_vm._v("Tooltip on Avatar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])]}}])},[_c('span',[_vm._v("Tooltip on Icon")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }